import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiList, FiPlayCircle, FiStar, FiInfo } from 'react-icons/fi';

const BottomNav = ({openInfoModal}) => {
  return (
    <div className="bottom-nav">
      
      <button className="info-button" onClick={openInfoModal}>
        <FiInfo size={24} />
        <span>Info</span>
      </button>


    </div>
  );
};

export default BottomNav;
