import React, { useState } from 'react';
import { FiSearch, FiUser, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaCity, FaFilm, FaBasketballBall, FaHeart, FaUniversity, FaUtensils, FaWineGlassAlt, FaChild } from 'react-icons/fa';

const TopNav = ({selectedCategory, setSelectedCategory}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setDropdownOpen(false);
  };

  const categoryMap = {
    family: 'Kinder',
    entertainment: 'Unterhaltung',
    city: 'Stadt Services',
    sport: 'Sport',
    lifestyle: 'Lifestyle',
    museum: 'Museum',
    food: 'Essen',
    heurigen: 'Heurigen'
  };

  const translateCategory = (id) => {
    return categoryMap[id] || id;
  }

  return (
    <div className="top-nav">
      <div className="selected-category" onClick={toggleDropdown}>
        {selectedCategory === 'entertainment' && <FaFilm size={20} />}
        {selectedCategory === 'sport' && <FaBasketballBall size={20} />}
        {selectedCategory === 'city' && <FaCity size={20} />}
        {selectedCategory === 'lifestyle' && <FaHeart size={20} />}
        {selectedCategory === 'museum' && <FaUniversity size={20} />}
        {selectedCategory === 'food' && <FaUtensils size={20} />}
        {selectedCategory === 'heurigen' && <FaWineGlassAlt size={20} />}
        {selectedCategory === 'family' && <FaChild size={20} />}
        <span>{translateCategory(selectedCategory)}</span>
        {dropdownOpen ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </div>
      {dropdownOpen && (
        <div className="event-type-selector">
          <div className="event-category" onClick={() => handleCategorySelect('entertainment')}>
            <FaFilm size={20} />
            <span>Unterhaltung</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('sport')}>
            <FaBasketballBall size={20} />
            <span>Sport</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('city')}>
            <FaCity size={20} />
            <span>Stadt Services</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('lifestyle')}>
            <FaHeart size={20} />
            <span>Lifestyle</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('museum')}>
            <FaUniversity size={20} />
            <span>Museum</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('food')}>
            <FaUtensils size={20} />
            <span>Essen</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('heurigen')}>
            <FaWineGlassAlt size={20} />
            <span>Heurigen</span>
          </div>
          <div className="event-category" onClick={() => handleCategorySelect('family')}>
            <FaChild size={20} />
            <span>Kinder</span>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default TopNav;
