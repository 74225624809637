
import React from 'react';
import { FiX } from 'react-icons/fi';
import './About.css';

const About = ({ closeModal }) => {
  return (
    <div className="info-modal-overlay">
      <div className="info-modal">
        <button className="close-button" onClick={closeModal}>
          <FiX size={24} />
        </button>
        <h2>Info</h2>
        <p>
        <font color="black">
        <b>Das ist ein Pilot</b>
        <br></br><br></br>
        Zusammenfassende Information was in Klosterneuburg heute, morgen und übermorgen los ist.<br></br><br></br>
        
        Quellen: Heurigenkalender, Stadtmarketing, Veranstalter<br></br>
        <br></br>
        Für die Richtigkeit und Aktualität wird nicht gehaftet.<br></br>
        <br></br>
        Gesammelt und zur Verfügung gestellt von:
        
        <br></br>        
        <br></br>                    
            Lamberti Labs FlexCo<br></br>
            Gonzagagasse 15/3c<br></br>
            1010 Wien

            <br></br>
        Fragen, Informationen und Feedback auch gerne an:
        
        <br></br>        
        <br></br>                    
            welcome@lamberti.at<br></br>
            +43 1 309 30 09<br></br>
          
        </font>

        </p>
      </div>
    </div>
  );
};

export default About;