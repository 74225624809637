import React, { Fragment, useEffect, useState } from 'react';
import { format, addDays, subDays } from 'date-fns';

const DateSelector = ( {selectedDate, setSelectedDate }) => {
  const [dates, setDates] = useState([]);

  const generateDates = (centerDate) => {
    const newDates = [];
    for (let i = 0; i <= 2; i++) {
      newDates.push(addDays(centerDate, i));
    }
    return newDates;
  };

  const isToday = (date) => {
    const today = new Date();
    
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const getWeekdayNameShort = (date) => {
    const daysOfWeek = [
      'So',
      'Mo',
      'Di',
      'Mi',
      'Do',
      'Fr',
      'Sa'
    ];
    return daysOfWeek[date.getDay()];
  };


  useEffect(() => {    
    setDates(generateDates(new Date()));
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    // setDates(generateDates(date));
  };

  return (
    <div className="date-selector">
    {dates.map((date, index) => (
      <div
        key={index}
        className={`date ${date.toDateString() === selectedDate.toDateString() ? 'selected' : ''}`}
        onClick={() => handleDateClick(date)}
      >
        {
          isToday(date) 
          ?
          <Fragment>
            <b>HEUTE</b>
          </Fragment>
          : 
          <Fragment>
            {getWeekdayNameShort(date)}<br></br>{format(date, 'dd.MM.')}
          </Fragment>
          
        }
      </div>
    ))}
  </div>
  );
};

export default DateSelector;
