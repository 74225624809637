import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import TopNav from './components/TopNav';
import DateSelector from './components/DateSelector';
import About from './components/About';
import Main from './components/Main';
import BottomNav from './components/BottomNav';
import Support from './components/Support';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/live" element={<Main />} />
          <Route path="/favorites" element={<Main />} />
          <Route path="/calendar" element={<Main />} />
          <Route path="/support" element={<Support />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
