
import React from 'react';
import { FiX } from 'react-icons/fi';
import './About.css';

const Support = ({ closeModal }) => {
  return (

      <div className="support">        
        
        
        <font color="black">
        <b>Today Klosterneuburg - Support Informationen</b>
        <br></br><br></br>
        Zusammenfassende Information was in Klosterneuburg heute, morgen und übermorgen los ist.<br></br><br></br>
        
        Quellen: Heurigenkalender, Stadtmarketing, Veranstalter<br></br>
        <br></br>
        Für die Richtigkeit und Aktualität wird nicht gehaftet.<br></br>
        <br></br>
        Gesammelt und zur Verfügung gestellt von:
        
        <br></br>        
        <br></br>                    
            Lamberti Labs FlexCo<br></br>
            Gonzagagasse 15/3c<br></br>
            1010 Wien

<p></p>
        Fragen, Informationen und Feedback auch gerne an:
        
        <br></br>        
        <br></br>                    
            welcome@lamberti.at<br></br>
            +43 1 309 30 09<br></br>          
        </font>

      </div>
      
    
  );
};

export default Support;