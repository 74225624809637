import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";

import TopNav from './TopNav';
import DateSelector from './DateSelector';
import EventList from './EventList';
import BottomNav from './BottomNav';
import About from './About';

const url = 'https://tkl-api.lamberti.at/events';
// const url = 'http://localhost:5001/events';

const Main = () => {
  const [events, setEvents] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('entertainment');  
  const [selectedDate, setSelectedDate] = useState(new Date());
    
  const openInfoModal = () => {
    setInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  const fetchEvents = async () => {
    try {
      let filter = {
        start: new Date(selectedDate.setHours(0, 0, 0, 0)),
        end: new Date(selectedDate.setHours(23, 59, 59, 999)),
        category: selectedCategory
      }
                
      let response = await axios.post(url, {filter});    
        let temp = response.data;
        temp = temp.map(event => ({ ...event, status: 'in-progress' }));
        setEvents(response.data);
      
    }        
    catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {

      fetchEvents(selectedCategory, selectedDate);


  }, [selectedCategory, selectedDate]); // Dependencies for subsequent changes


  return (
    <Fragment>
        <TopNav selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        
        <EventList events={events} selectedCategory={selectedCategory} selectedDate={selectedDate}/>
        <BottomNav openInfoModal={openInfoModal} />
        {isInfoModalOpen && <About closeModal={closeInfoModal} />}
    </Fragment>
  );
}

export default Main;