import React from 'react';
import {Fragment, useEffect, useState} from "react"; 
import { format, addDays } from 'date-fns';

const EventListItemHeuriger = ({event, selectedCategory, selectedDate}) => {
    const isTimeInFuture = (date) => {
        const now = new Date();      
        const givenHours = date.getHours();
        const givenMinutes = date.getMinutes();
      
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
      
        if (givenHours > currentHours) {
          return true;
        } else if (givenHours === currentHours) {
          return givenMinutes > currentMinutes;
        } else {
          return false;
        }
      };

    const getWeekdayName = (date) => {
        const daysOfWeek = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday'
        ];
        return daysOfWeek[date.getDay()];
      };

      const isClosedOnDay = (date, closingDays) => {
        const weekdayName = getWeekdayName(date);
        return closingDays[weekdayName];
      };

      const isToday = (date) => {
        const today = new Date();
        
        return date.getDate() === today.getDate() &&
               date.getMonth() === today.getMonth() &&
               date.getFullYear() === today.getFullYear();
      };

      let status = 'upcoming'
      
// Liste Feiertage fehlt noch #todo
    let startPrefix;
    let startSuffix = ' Uhr'
    if (isToday(selectedDate)) {        
        if(isTimeInFuture(new Date(event.start))) {
            status = 'starting-soon';
            startPrefix = 'Ab '            
        } else {
            status = 'in-progress';
            startPrefix = 'Seit '
        }
    } else {
        if(new Date() > selectedDate) status = "ended";
    }
    
    let isClosed = (event?.closingDays) ? isClosedOnDay(selectedDate, event.closingDays) : false;
    (isClosed) ? status = 'ended' : status = status;
    
    if(new Date() > event.end) status = "ended";
  
  return (
    <Fragment>          
            <div className="event" >
              <div className="event-info">                
                <span className="event-name">{event.title}{(isClosed ? ' RUHETAG' : '')}</span>                                
                <span className="event-time">{
                (event?.openingsDaysText) 
                ?                
                <Fragment>
                    {event.openingsDaysText}
                </Fragment>
                :
                <Fragment></Fragment>
                }
                </span>
              </div>              
              <div className={`event-status ${status}`}>
                <div className="progress-bar"></div>
              </div>
            </div>
  </Fragment>
  );
};

export default EventListItemHeuriger;
