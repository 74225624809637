import React from 'react';
import {Fragment, useEffect, useState} from "react"; 
import { format, addDays } from 'date-fns';
import { FaExternalLinkAlt } from 'react-icons/fa';

const EventListItem = ({event, selectedCategory, selectedDate}) => {
  const isToday = (date) => {
    const today = new Date();
    
    return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
  };

  const isTimeInFuture = (date) => {
      const now = new Date();      
      const givenHours = date.getHours();
      const givenMinutes = date.getMinutes();
    
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
    
      if (givenHours > currentHours) {
        return true;
      } else if (givenHours === currentHours) {
        return givenMinutes > currentMinutes;
      } else {
        return false;
      }
  };

// Liste Feiertage fehlt noch #todo
  let status = 'neutral'      
  let startPrefix;
  if (isToday(selectedDate)) {        
    if(isTimeInFuture(new Date(event.start))) {
      status = 'starting-soon';
      startPrefix = 'Ab '            
    } else {
      status = 'in-progress';
      startPrefix = 'Seit '
    }
    if(new Date(event.end) < new Date()) {
      status = 'ended'
    }
  }

  const getWeekdayName = (date) => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ];
    return daysOfWeek[date.getDay()];
  };

  const isClosedOnDay = (date, closingDays) => {
    const weekdayName = getWeekdayName(date);
    return closingDays[weekdayName];
  };

  let isClosed = (event?.closingDays) ? isClosedOnDay(selectedDate, event.closingDays) : false;
  let hide = false;
  hide = isClosed;
  
  return (
    <Fragment>
    {
      (!hide)
      ?
      <Fragment>
        <div className="event" >
              <div className="event-info">                
                <span className="event-name">{event.title}
                  {
                    (event.url)
                    ?
                    <Fragment>
                        <span className='event-link-icon'>
                        &nbsp;<a href={event.url} target="_blank" rel="noopener noreferrer" className='event-link-icon'>
                          <FaExternalLinkAlt size={13} />
                        </a>                      
                        </span>                                          
                    </Fragment>
                    :
                    <Fragment></Fragment>
                  }
                </span>
                {
                  ( event.venue === event.title)
                  ?
                  <Fragment></Fragment>
                  :
                  <Fragment>
                    <span className="event-location">{event.venue}</span>
                  </Fragment>
                }
                {
                  (new Date(event.start).getTime() == new Date(event.end).getTime())
                  ?
                  <Fragment></Fragment>
                  :
                  <Fragment> 
                    <span className="event-time">{
                      (event?.start) ? format(new Date(event.start), 'HH:mm') : ''
                    }{ (event?.end) ? ' - ' + format(new Date(event.end), 'HH:mm') : ''
                    }
                    </span>
                  </Fragment>
                }
              </div>
              <div className={`event-status ${status}`}>
                <div className="progress-bar"></div>
              </div>
            </div>

      </Fragment>
      :
      <Fragment></Fragment>
    }        
  </Fragment>
  );
};

export default EventListItem;
