import React from 'react';
import {Fragment, useEffect, useState} from "react"; 
import { format, addDays } from 'date-fns';

import EventListItem from './EventListItem';
import EventListItemHeuriger from './EventListItemHeuriger';

const EventList = ({events, selectedCategory, selectedDate}) => {  
  return (
    <div className="event-list">
      {
        (events?.length > 0)
        ?               
        <Fragment>
          {events.sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => (                
             (selectedCategory === 'heurigen')
             ?
             <EventListItemHeuriger key={event._id} event={event} selectedCategory={selectedCategory} selectedDate={selectedDate}></EventListItemHeuriger>
             :             
             <EventListItem key={event._id} event={event} selectedCategory={selectedCategory} selectedDate={selectedDate}></EventListItem>                       
          ))}
        </Fragment>
        :
        <div className="event">          
            Keine Daten
        </div>
      }  
  </div>
  );
};

export default EventList;
